<template>
  <v-container fluid class="content-wrapper" style="overflow: hidden;">
    <div class="d-flex flex-row justify-start align-center mb-5">
    <h4 class="fw-600 mb-0">{{ $t("SigninPromotion.title") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div v-if="loadingData" class="">
      <v-skeleton-loader
        type="article, divider, article"
      ></v-skeleton-loader>
    </div>
    <v-row v-else row wrap>
      <v-col cols="12">
        <h5 class="fw-600">{{ $t("SigninPromotion.campaign") }}</h5>
      </v-col>
      <v-col cols="12">
        <div>
          <v-btn
            @click="campaign_active = true"
            dense
            light
            :class="campaign_active ? 'btn-group-active' : 'btn-group'"
            elevation="0"
          >
            <span class="textsyle">{{ $t("SigninPromotion.active") }}</span>
          </v-btn>
          <v-btn
            @click="campaign_active = false"
            dense
            light
            :class="!campaign_active ? 'btn-group-active' : 'btn-group'"
            elevation="0"
          >
            <span class="textsyle">{{ $t("SigninPromotion.expired") }}</span>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="card card-shadow mb-0" style="height: auto;">
          <v-data-table
            v-if="campaign_active"
            :headers="hCampaignActive"
            :items="campaignActiveItems"
            :no-data-text="$t('SignupStep.nodata')"
            class="elevation-1"
            :loading="loadingCampaign"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon x-large color="#28d094">mdi-tag-outline</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="title-xsm mb-0"
                ><span class="fw-600">{{ item.name }}<br /></span
                >{{ $t("SigninPromotion.discount") }} {{ item.percent }}%
                {{ $t("SigninPromotion.ormore") }}</span
              >
            </template>
            <template v-slot:item.daterange="{ item }">
              <span class="title-xsm mb-0"
                >{{ item.date_start }} -<br />
                {{ item.date_end }}</span
              >
            </template>
            <template v-slot:item.canadjust="{ item }">
              <span class="title-xsm mb-0"
                ></span
                >{{ $t("SigninPromotion.adjustpromotion") }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                depressed
                dark
                color="#666ee8"
                class="btn-min-width"
                style="text-transform: capitalize !important"
                @click="gotoLink('editcampaign', item._id, null)"
              >
                <span class="textstyle">{{ $t("SigninPromotion.join") }}</span>
              </v-btn>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            :headers="hCampaignActive"
            :items="campaignExpiredItems"
            :no-data-text="$t('SignupStep.nodata')"
            class="elevation-1"
            :loading="loadingCampaign"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon x-large color="#28d094">mdi-tag-outline</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="title-xsm mb-0"
                ><span class="fw-600">{{ item.name }}<br /></span
                >{{ $t("SigninPromotion.discount") }} {{ item.percent }}%
                {{ $t("SigninPromotion.ormore") }}</span
              >
            </template>
            <template v-slot:item.daterange="{ item }">
              <span class="title-xsm mb-0"
                >{{ item.date_start }} -<br />
                {{ item.date_end }}</span
              >
            </template>
            <template v-slot:item.canadjust="{ item }">
              <span class="title-xsm mb-0"
                ></span
                >{{ $t("SigninPromotion.adjustpromotion") }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
            </template>
          </v-data-table>
        </div>
      </v-col>

      <v-col cols="12">
        <h5 class="fw-600">{{ $t("SigninPromotion.promotions") }}</h5>
      </v-col>
      <v-col cols="12" class="d-flex flex-row align-center justify-space-between">
        <div>
          <v-btn
            @click="promotion_active = true"
            dense
            light
            :class="promotion_active ? 'btn-group-active' : 'btn-group'"
            elevation="0"
          >
            <span class="textsyle">{{ $t("SigninPromotion.active") }}</span>
          </v-btn>
          <v-btn
            @click="promotion_active = false"
            dense
            light
            :class="!promotion_active ? 'btn-group-active' : 'btn-group'"
            elevation="0"
          >
            <span class="textsyle">{{ $t("SigninPromotion.expired") }}</span>
          </v-btn>
        </div>
        <v-btn
            depressed
            dark
            color="#666ee8"
            class="btn-min-width"
            style="text-transform: capitalize !important"
            @click="gotoLink('selectpromotiontype', null, null)"
            >
            <span class="textstyle">{{ $t("SigninPromotion.choosenewpromotion") }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="mb-5">
        <div class="card card-shadow mb-0" style="height: auto;">
          <v-data-table
            v-if="promotion_active"
            id="promotionactive"
            :headers="hPromotionActive"
            :items="promotionActiveItems"
            :no-data-text="$t('SignupStep.nodata')"
            class="elevation-1 mytable"
            @click:row="updatePromotion"
            :loading="loadingPromotion"
          >
            <template v-slot:item.name="{ item }">
              <div class="d-flex align-center justify-start">
                <v-icon color="#666ee8" large>mdi-cellphone</v-icon>
                <span class="title-xsm mb-0 font-weight-bold ml-2" style="color: #666ee8;">{{ item.name }}</span>
              </div>
            </template>
            <template v-slot:item.discount="{ item }">
              <span class="title-xsm mb-0 font-weight-bold"
                >{{ item.discount }}%</span
              >
            </template>
            <template v-slot:item.rangecanreserve="{ item }">
              <span v-if="item.canreserve_start && item.canreserve_end" class="title-xsm mb-0 font-weight-bold"
                >{{ item.canreserve_start }}<br />
                {{ $t("SigninPromotion.to")}}<br />
                {{ item.canreserve_end }}</span
              >
              <span v-else class="title-xsm mb-0"
                >{{ $t("SignupStep.nodata")}}</span
              >
            </template>
            <template v-slot:item.rangestay="{ item }">
              <span v-if="item.stay_start && item.stay_end" class="title-xsm mb-0 font-weight-bold"
                >{{ item.stay_start }}<br />
                {{ $t("SigninPromotion.to")}}<br />
                {{ item.stay_end }}</span
              >
              <span v-else class="title-xsm mb-0"
                >{{ $t("SignupStep.nodata")}}</span
              >
            </template>
            <template v-slot:item.allreserve="{ item }">
              <span class="title-xsm mb-0 font-weight-bold"
                >{{ item.reserved }}/{{ item.all_reserve }}</span
              >
            </template>
            <template v-slot:item.roomnight="{ item }">
              <span class="title-xsm mb-0 font-weight-bold"
                >{{ item.roomnight }}</span
              >
            </template>
            <template v-slot:item.avgprice="{ item }">
              <span class="title-xsm mb-0 font-weight-bold"
                >฿{{ priceFormatter(item.avgprice) }}</span
              >
            </template>
            <template v-slot:item.totalprice="{ item }">
              <span class="title-xsm mb-0 font-weight-bold"
                >฿{{ priceFormatter(item.totalprice) }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon @click.stop.prevent="delPromotion(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            id="promotioninactive"
            :headers="hPromotionActive"
            :items="promotionExpiredItems"
            :no-data-text="$t('SignupStep.nodata')"
            class="elevation-1"
            :loading="loadingPromotion"
          >
            <template v-slot:item.name="{ item }">
              <v-icon color="#666ee8" large>mdi-cellphone</v-icon><span class="title-xsm mb-0 font-weight-bold" style="color: #666ee8;"
                >{{ item.name }}</span
              >
            </template>
            <template v-slot:item.discount="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                >{{ item.discount }}%</span
              >
            </template>
            <template v-slot:item.rangecanreserve="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;">
                <!-- {{ item.canreserve_start }}<br />
                {{ $t("SigninPromotion.to")}}<br />
                {{ item.canreserve_end }} -->
                {{ $t("SigninPayout.expired")}}
              </span>
            </template>
            <template v-slot:item.rangestay="{ item }">
              <div class="d-flex flex-column align-center justify-center">
                <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                  >
                  {{ $t("SigninPromotion.soldout")}}</span
                >
                <v-btn
                  depressed
                  dark
                  color="#ff9149"
                  class="btn-min-width mt-2"
                  style="text-transform: capitalize !important"
                  @click="updatePromotion(item)"
                  >
                  <span class="textstyle">{{ $t("SigninPromotion.edit") }}</span>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.allreserve="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                >{{ item.reserved }}/{{ item.all_reserve }}</span
              >
            </template>
            <template v-slot:item.roomnight="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                >{{ item.roomnight }}</span
              >
            </template>
            <template v-slot:item.avgprice="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                >฿{{ priceFormatter(item.avgprice) }}</span
              >
            </template>
            <template v-slot:item.totalprice="{ item }">
              <span class="title-xsm mb-0 font-weight-bold" style="color: #6b6f82;"
                >฿{{ priceFormatter(item.totalprice) }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon @click.stop.prevent="delPromotion(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninPromotionScreen",
  metaInfo: {
    title: "Promotion",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    propertyData: null,
    value_element: "",
    campaign_active: true,
    promotion_active: true,
    hCampaignActive: [],
    campaignActiveItems: [],
    campaignExpiredItems: [],
    hPromotionActive: [],
    promotionActiveItems: [],
    promotionExpiredItems: [],
    itemsPromotionType: [],
    itemsCountryPromotionType: [],
    loadingCampaign: true,
    loadingPromotion: true,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true
      self.initPromotionType();
      self.initPropertyData();

      self.hCampaignActive = [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "icon",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "daterange",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "canadjust",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ];

      self.hPromotionActive = [
        {
          text: self.$t("SigninPromotion.title_name"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: self.$t("SigninPromotion.title_discount"),
          align: "center",
          sortable: false,
          value: "discount",
        },
        {
          text: `${self.$t("SigninPromotion.title_range")}${self.$t(
            "SigninPromotion.title_canreserve"
          )}`,
          align: "center",
          sortable: false,
          value: "rangecanreserve",
        },
        {
          text: self.$t("SigninPromotion.title_stay"),
          align: "center",
          sortable: false,
          value: "rangestay",
        },
        {
          text: self.$t("SigninPromotion.title_allreserved"),
          align: "center",
          sortable: false,
          value: "allreserve",
        },
        {
          text: self.$t("SigninPromotion.title_roomnight"),
          align: "center",
          sortable: false,
          value: "roomnight",
        },
        {
          text: self.$t("SigninPromotion.title_avgperday"),
          align: "center",
          sortable: false,
          value: "avgprice",
        },
        {
          text: self.$t("SigninPromotion.title_total"),
          align: "center",
          sortable: false,
          value: "totalprice",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ];

      setTimeout(() => {
        self.loadingData = false
      }, 1500);
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempCampaign = [];
      var tempCampaignExpired = [];
      var tempPromotion = [];
      var tempPromotionExpired = [];
      self.loadingCampaign = true;
      self.loadingPromotion = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("loadingtillend");
        self.propertyData = temp;
        temp?.campaign?.map((el) => {
          var dateValue = el.date_end;
          el.start_format = moment(el.date_start).locale("th").format("DD/MM/YYYY");
          el.end_format = moment(el.date_end).locale("th").format("DD/MM/YYYY");
          var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
          var endDate = new Date(new Date(dateValue).setUTCHours(0, 0, 0, 0));

          if (endDate < currentDate) {
            el.date_past = true;
          } else {
            el.date_past = false;
          }

          if (el.status == "active") {
            tempCampaign.push({
              ...el,
              name: el?.campaign_name || "",
              detail: el?.campaign_detail || "",
              percent: el?.discount_percent || "",
              date_start: moment(el?.stay?.start_date).format("DD/MM/YYYY"),
              date_end: moment(el?.stay?.end_date).format("DD/MM/YYYY"),
              canadjust: true,
            });
            } else {
              tempCampaignExpired.push({
              ...el,
              name: el?.campaign_name || "",
              detail: el?.campaign_detail || "",
              percent: el?.discount_percent || "",
              date_start: moment(el?.stay?.start_date).format("DD/MM/YYYY"),
              date_end: moment(el?.stay?.end_date).format("DD/MM/YYYY"),
              canadjust: false,
            });
          }
        });
        self.campaignActiveItems = tempCampaign;
        self.campaignExpiredItems = tempCampaignExpired;
        self.loadingCampaign = false;

        temp?.promotion?.map((el) => {
          // console.log(el)
          var dateValue = el.booking?.end_date;
          el.start_format = moment(el.booking?.start_date).locale("th").format("DD/MM/YYYY");
          el.end_format = moment(el.booking?.end_date).locale("th").format("DD/MM/YYYY");
          var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
          var endDate = new Date(new Date(dateValue).setUTCHours(0, 0, 0, 0));

          if (endDate < currentDate) {
            el.date_past = true;
          } else {
            el.date_past = false;
          }

          if (el.status == "active") {
            tempPromotion.push({
              ...el,
              name:
                el?.promotion_name || self.$t("SigninPromotion.nameofpromotion"),
              discount: el?.discount_percent || 0,
              canreserve_start: el?.booking?.start_date
                ? moment(el?.booking?.start_date).format("DD/MM/YYYY")
                : "",
              canreserve_end: el?.booking?.end_date
                ? moment(el?.booking?.end_date).format("DD/MM/YYYY")
                : "",
              stay_start: el?.stay?.start_date
                ? moment(el?.stay?.start_date).format("DD/MM/YYYY")
                : "",
              stay_end: el?.stay?.end_date
                ? moment(el?.stay?.end_date).format("DD/MM/YYYY")
                : "",
              all_reserve: 0,
              reserved: 0,
              roomnight: 0,
              avgprice: 0,
              totalprice: 0,
            });
          } else {
            tempPromotionExpired.push({
              ...el,
              name:
                el?.promotion_name || self.$t("SigninPromotion.nameofpromotion"),
              discount: el?.discount_percent || 0,
              canreserve_start: el?.booking?.start_date
                ? moment(el?.booking?.start_date).format("DD/MM/YYYY")
                : "",
              canreserve_end: el?.booking?.end_date
                ? moment(el?.booking?.end_date).format("DD/MM/YYYY")
                : "",
              stay_start: el?.stay?.start_date
                ? moment(el?.stay?.start_date).format("DD/MM/YYYY")
                : "",
              stay_end: el?.stay?.end_date
                ? moment(el?.stay?.end_date).format("DD/MM/YYYY")
                : "",
              all_reserve: 0,
              reserved: 0,
              roomnight: 0,
              avgprice: 0,
              totalprice: 0,
            });
          }
        });
        self.promotionActiveItems = tempPromotion;
        self.promotionExpiredItems = tempPromotionExpired;
        // self.promotionExpiredItems = [
        //   {
        //     _id: "00000",
        //     name: self.$t("SigninPromotion.nameofpromotion"),
        //     discount: 30,
        //     canreserve_start: moment().format("DD/MM/YYYY"),
        //     canreserve_end: moment().format("DD/MM/YYYY"),
        //     stay_start: moment().format("DD/MM/YYYY"),
        //     stay_end: moment().format("DD/MM/YYYY"),
        //     all_reserve: 20,
        //     reserved: 9,
        //     roomnight: 15,
        //     avgprice: 6759.17,
        //     totalprice: 101387.5,
        //   },
        // ];
        self.loadingPromotion = false;
        EventBus.$emit("endloading");
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gotoLink(action, campaignId, promotionId) {
      EventBus.$emit("loadingtillend");
      const link = {
        name:
          action == "editcampaign"
            ? "SigninCampaignEditScreen"
            : "SigninPromotionTypeScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          campaignId,
          promotionId,
        },
      };
      EventBus.$emit("changePathname", link);
      EventBus.$emit("endloading");
    },
    async initPromotionType() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var temp = [];
      var tempLimit = [];
      var tempType = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/promotion",
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        temp?.map((el) => {
          if (el.type == "country_limit")
            tempLimit.push({
              ...el,
              iconname: "mdi-map",
              iconcolor: "#1e9ff2",
              linkName: "SigninPromotionTypeNationUpdateScreen",
            });
          else
            tempType.push({
              ...el,
              iconname:
                el?.type == "basic"
                  ? "mdi-map-marker-outline"
                  : el?.type == "free"
                  ? "mdi-calendar-blank-outline"
                  : "mdi-cellphone",
              iconcolor:
                el?.type == "basic"
                  ? "#28d094"
                  : el?.type == "free"
                  ? "#ff9149"
                  : "#666ee8",
              linkName:
                el?.type == "basic"
                  ? "SigninPromotionTypeBasicUpdateScreen"
                  : el?.type == "free"
                  ? "SigninPromotionTypeFreeUpdateScreen"
                  : "",
            });
        });
        self.itemsPromotionType = tempType;
        self.itemsCountryPromotionType = tempLimit;
      }
    },
    updatePromotion(item) {
      EventBus.$emit("loadingtillend");
      const self = this;
      const tempCountry = self?.itemsCountryPromotionType?.find(
        (p) => p._id == item.promotion
      );
      const tempType = self.itemsPromotionType?.find(
        (p) => p._id == item.promotion
      );
      const link = {
        name: tempCountry?.linkName || tempType?.linkName,
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          promotionTypeId: item.promotion,
          promotionId: item._id,
        },
      };
      EventBus.$emit("changePathname", link);
      EventBus.$emit("endloading");
    },
    delPromotion(item) {
      const self = this;
      // console.log(item);

      swal(
        `${self.$t("Alert.qtneedtoremove")} ${item.promotion_name} ${self.$t(
          "Alert.qtyesorno"
        )} ?`,
        {
          dangerMode: true,
          buttons: {
            cancel: self.$t("Alert.btn_no"),
            confirm: {
              text: self.$t("Alert.btn_yes"),
              value: "confirm",
            },
          },
        }
      ).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.delete(
              process.env.VUE_APP_API +
                `/property/promotion?promotion_id=${item._id}`,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") ||
                    self?.$store?.state?.language,
                },
              }
            );
            if (res?.status == 200) {
            } else {
              swal(
                self.$t("Alert.warn_title"),
                res?.data?.message || "Please try again",
                self.$t("Alert.warn_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            }
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || "Please try again",
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            self.initAll();
          }
        }
      });
    },
  },
};
</script>

<style>
#promotionactive tbody tr td {
  height: 5.5rem !important;
}

#promotioninactive tbody tr td {
  height: 5.5rem !important;
}

/* .mytable .v-table tbody tr:not(:last-child) {
  border-bottom: none;
} */
</style>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/promotion.css";

#promotioninactive tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #6b6f82 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn-group {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #fff !important;
  color: #666ee8 !important;
}

.btn-group-active {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #666ee8 !important;
  color: #fff !important;
}
</style>